import { getCountryName } from 'utils/internationalization';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getCityStateString(address: Record<string, any>) {
  return [
    address.city,
    address.stateCode,
    address.country === 'US' ? null : getCountryName(address.country),
  ]
    .filter((part) => part)
    .join(', ');
}
