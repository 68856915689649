import invariant from 'invariant';

import { COUNTRIES } from 'utils/constants/general/countries';
import { LOCALES } from 'utils/constants/general/locales';
import { US_STATES } from 'utils/constants/general/usStates';

export function getLocaleName(localeId: string) {
  const locale = LOCALES.find((l) => l.id === localeId);
  invariant(locale, `Invalid locale ID: ${localeId}`);
  return locale.name;
}

export function getCountryName(countryId: string) {
  const country = COUNTRIES.find(({ id }) => id === countryId);
  if (country) return country.name;
  return '';
}

export function getUsStateName(stateId: string) {
  const state = US_STATES.find(({ id }) => id === stateId);
  if (state) return state.name;
  return '';
}
