import { ReactNode, useState } from 'react';

import { ApiOrgId } from 'api/types/ApiTypedId';
import {
  orgDashboardApiHideListing,
  orgDashboardApiUnhideListing,
} from 'modules/orgDashboard/api/listing/orgDashboardApiListing';
import { pushFlashMessage } from 'zustand-stores/flashMessagesStore';

type Props = {
  listingId: string;
  listingType: 'JOB' | 'INTERNSHIP' | 'VOLOP' | 'EVENT';
  orgId: ApiOrgId;
  render: (fn: {
    hideListing: () => Promise<void>;
    unhideListing: () => Promise<void>;
    submitting: boolean;
  }) => ReactNode;
  onHide?: (actionType: 'LISTING_HIDDEN' | 'LISTING_UNHIDDEN') => void;
};

export function HideListingContainer({
  listingId,
  listingType,
  orgId,
  render,
  onHide,
}: Props) {
  const [submitting, setSubmitting] = useState(false);

  const toggleListingHidden = (hide: boolean) => {
    setSubmitting(true);

    const toggle = hide
      ? orgDashboardApiHideListing
      : orgDashboardApiUnhideListing;

    return toggle({
      orgId,
      listingId,
      listingType,
    }).then(() => {
      setSubmitting(false);
      pushFlashMessage({ type: hide ? 'LISTING_HIDDEN' : 'LISTING_UNHIDDEN' });

      onHide?.(hide ? 'LISTING_HIDDEN' : 'LISTING_UNHIDDEN');
    });
  };

  const hideListing = () => toggleListingHidden(true);

  const unhideListing = () => toggleListingHidden(false);

  return <>{render({ hideListing, unhideListing, submitting })}</>;
}
