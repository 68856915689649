import { ApiActionType } from 'modules/listing/api/volop/types/ApiActionType';

export const ACTION_TYPE: Array<{
  id: ApiActionType | 'NONE';
  name: string;
}> = [
  {
    id: 'VOLOP',
    name:
      /* TRANSLATORS: "Volunteer +" type */
      getText('Volunteer Opportunity'),
  },
  {
    id: 'LOAN',
    name:
      /* TRANSLATORS: "Volunteer +" type */
      getText('Loan'),
  },
  {
    id: 'DONATION',
    name:
      /* TRANSLATORS: "Volunteer +" type */
      getText('Donation'),
  },
  {
    id: 'PETITION',
    name:
      /* TRANSLATORS: Action Opp type */
      getText('Petition'),
  },
  {
    id: 'OTHER',
    name:
      /* TRANSLATORS: "Volunteer +" type */
      getText('Other Opportunity'),
  },
];
