export const LISTING_TYPE_PLURAL_HUMAN_NAME_MAP = {
  ALL: getText('all listings'),
  JOB: getText('jobs'),
  INTERNSHIP: getText('internships'),
  VOLOP: getText('volunteer opportunities'),
  ORG: getText('organizations'),
  EVENT: getText('events'),
  IMPORTED: getText('opportunities'),
};

export const LISTING_TYPE_PLURAL_HUMAN_TRACKING_NAME_MAP = {
  JOB: 'jobs',
  INTERNSHIP: 'internships',
  VOLOP: 'volunteer opportunities',
  ORG: 'organizations',
  EVENT: 'events',
  IMPORTED: 'opportunities',
};
