import { useCallback, useState } from 'react';

import { SearchType } from 'modules/search/types/SearchType';
import {
  saveListing,
  unsaveListing,
} from 'store/ducks/userEnvironment.actions';
import { useMainDispatch } from 'store/hooks/useMainDispatch';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { LISTING_TYPE_HUMAN_NAME_MAP } from 'utils/constants/general/listingTypeHumanNameMap';
import { stopRouteLoadingAnimation } from 'utils/ui/routeLoadingAnimation';
import { showModal } from 'zustand-stores/modalStore';

export type SaveListingType = (
  e?: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
) => Promise<void>;

type UseSaveListingProps = {
  listingType: SearchType | 'IMPORTED';
  listingName: string;
  listingId: string;
  orgId?: string | null;
  orgName?: string | null;
  orgType?: string | null;
};

export const useSaveListing = ({
  listingType,
  listingName,
  listingId,
  orgId,
  orgName,
  orgType,
}: UseSaveListingProps) => {
  const [showOrgModal, setShowOrgModal] = useState(false);
  const dispatch = useMainDispatch();
  const { user, isUserLoaded } = useUserEnvironment();

  const loggedIn = Boolean(user);
  const saved =
    user?.savedListings.some((listing) => listing.id === listingId) || false;

  const saveOrg = useCallback(() => {
    if (orgId) dispatch(saveListing(orgId, 'ORG'));
  }, [dispatch, orgId]);

  const save: SaveListingType = useCallback(async () => {
    if (loggedIn) {
      await dispatch(saveListing(listingId, listingType));
      const savedOrg = orgId && user?.savedListings.some((l) => l.id === orgId);

      if (
        !savedOrg &&
        orgId &&
        orgName &&
        listingType &&
        ['JOB', 'VOLOP', 'INTERNSHIP'].includes(listingType)
      ) {
        setShowOrgModal(true);
      }
    } else {
      showModal('EMAIL_SIGNUP', {
        title: `Save this ${LISTING_TYPE_HUMAN_NAME_MAP[listingType]}`,
        savedListing: {
          id: listingId,
          type: listingType,
          name: listingName,
        },
        redirectTo: `${window.location.pathname}${window.location.search}`,
      });
    }

    trackClicked('Save Listing', {
      listing_type: LISTING_TYPE_HUMAN_NAME_MAP[listingType],
      listing_id: listingId,
      org_id: orgId,
      org_type: orgType,
    });

    stopRouteLoadingAnimation();
  }, [
    loggedIn,
    dispatch,
    listingId,
    listingType,
    listingName,
    orgId,
    orgName,
    orgType,
    user?.savedListings,
  ]);

  const unsave = useCallback(
    () => dispatch(unsaveListing(listingId, listingType)),
    [dispatch, listingId, listingType],
  );

  return {
    saved,
    saveOrg,
    setShowOrgModal,
    showOrgModal,
    canSave: isUserLoaded && listingType !== 'IMPORTED',
    saveListing: save,
    unsaveListing: unsave,
  };
};
