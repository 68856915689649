import { IconName } from 'components/Icon/IconName';
import { ApiActionType } from 'modules/listing/api/volop/types/ApiActionType';

type Args = {
  listingType: 'JOB' | 'INTERNSHIP' | 'VOLOP' | 'EVENT' | 'IMPORTED' | 'ORG';
  actionType: ApiActionType | undefined;
};

export function getListingTypeLabelIcon({
  listingType,
  actionType,
}: Args): IconName {
  switch (listingType) {
    case 'JOB':
    case 'INTERNSHIP':
      return 'sign-path';

    case 'VOLOP':
    case 'IMPORTED':
      switch (actionType) {
        case 'DONATION':
          return 'coins';
        case 'PETITION':
          return 'clipboard';
        case 'LOAN':
          return 'money';
        default:
          return 'hand';
      }

    case 'EVENT':
      return 'star-calendar';

    case 'ORG':
      return 'building';
  }
}
