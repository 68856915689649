import { ApiOrgId } from 'api/types/ApiTypedId';
import { LISTING_TYPE_URL_SEGMENT_MAP } from 'utils/constants/general/listingTypeUrlSegmentMap';
import { del } from 'utils/http/del';
import { getJson } from 'utils/http/getJson';
import { postJson } from 'utils/http/postJson';
import { titleCaseToCamelCase } from 'utils/string';

import { OrgDashboardApiListing } from './types/OrgDashboardApiListing';
import { OrgDashboardApiRepostedListing } from './types/OrgDashboardApiRepostedListing';

export function orgDashboardApiHideListing({
  orgId,
  listingId,
  listingType,
}: {
  orgId: ApiOrgId;
  listingId: string;
  listingType: string;
}): Promise<OrgDashboardApiListing> {
  return postJson(
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    `/data/orgdashboard/${orgId}/${LISTING_TYPE_URL_SEGMENT_MAP[listingType]}/${listingId}/hide`,
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
  ).then((response) => response[titleCaseToCamelCase(listingType)]);
}

export function orgDashboardApiUnhideListing({
  orgId,
  listingId,
  listingType,
}: {
  orgId: ApiOrgId;
  listingId: string;
  listingType: 'JOB' | 'INTERNSHIP' | 'VOLOP' | 'EVENT';
}) {
  return postJson(
    `/data/orgdashboard/${orgId}/${LISTING_TYPE_URL_SEGMENT_MAP[listingType]}/${listingId}/unhide`,
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
  ).then((response) => response[titleCaseToCamelCase(listingType)]);
}

export function orgDashboardApiDeleteListing({
  orgId,
  listingId,
  listingType,
}: {
  orgId: ApiOrgId;
  listingId: string;
  listingType: string;
}) {
  return del(
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    `/data/orgdashboard/${orgId}/${LISTING_TYPE_URL_SEGMENT_MAP[listingType]}/${listingId}`,
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
  ).then((response) => response[titleCaseToCamelCase(listingType)]);
}

export async function fetchRepostedListings(
  orgId: ApiOrgId,
): Promise<OrgDashboardApiRepostedListing[]> {
  return getJson<{ listings: OrgDashboardApiRepostedListing[] }>(
    `/data/orgdashboard/${orgId}/published-reposts`,
  ).then(({ listings }) => listings);
}
