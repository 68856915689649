export const LISTING_TYPE_HUMAN_NAME_MAP = {
  JOB: getText('job'),
  INTERNSHIP: getText('internship'),
  VOLOP: getText('volunteer opportunity'),
  EVENT: getText('event'),
  ORG: getText('organization'),
  USER: getText('user'),
  CANDIDATE: getText('candidate'),
  IMPORTED: getText('opportunity'),
};

export const getListingTypeHumanName = (listing: {
  type: keyof typeof LISTING_TYPE_HUMAN_NAME_MAP;
}) => LISTING_TYPE_HUMAN_NAME_MAP[listing.type];
