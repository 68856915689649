import styled from 'styled-components';

import { Box } from 'components/Box';
import { IdealistLogo } from 'components/Logo/IdealistLogo';
import { H5 } from 'components/Text/H5';
import { colors } from 'theme/theme';

const StyledH5 = styled(H5)`
  color: ${colors.mediumContentGrey};
  margin: 0 0 0 10px;
  letter-spacing: 1.8px;
  font-weight: 400;
  font-size: 12px;
`;

export function IdealistDayLabel() {
  return (
    <Box display="flex" textTransform="uppercase">
      <IdealistLogo height={20} />
      <StyledH5>{getText('Idealist Day Event')}</StyledH5>
    </Box>
  );
}
